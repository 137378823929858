import * as express from 'express';
import { Logger } from './models';
import decode from 'jwt-decode';
import { isSerializable, ServerThisContext } from './misc';
import _ from 'lodash';
import { dateFormatters, DateInput, translate as i8nTranslate, initializeI18n } from '@ollie-sports/i18n';

export function registerFunctionsWithExpress(p: {
  fns: any;
  emitAccountId: (accountId: string) => void;
  emitFnFinish: (p: {
    fnName: string;
    status: 'success' | 'error';
    fnExecutionTimeMS: number;
    request: express.Request;
    details?: any;
  }) => Promise<void>;
  expressApp: express.Express;
  fnAuthKey: string; // Endpoints are only registered if they have a auth function attached.  This a) Allows different auth for different envs (admin vs web-client), b) prevents us from exposing an endpoint accidently
  apiPrefix: string;
  logger?: Logger;
}) {
  let fnNames = Object.keys(p.fns);

  for (let i = 0; i < fnNames.length; i++) {
    let fnName = fnNames[i];
    let refinedApiPath = p.apiPrefix
      .split('/')
      .filter((n) => n.length > 0)
      .join('/');
    let apiPath = `/${refinedApiPath}/${fnName}`;

    let hasAuthFn = typeof p.fns[fnName][p.fnAuthKey] === 'function';

    p.expressApp.post(apiPath, async (req: express.Request, res: express.Response) => {
      const START = Date.now();
      const accountId = attemptToDetermineUid(req);
      try {
        if (p.logger) {
          p.logger({ fnName: fnName, details: { body: req.body } });
        }

        if (!hasAuthFn) {
          const msg = `No auth defined for this function. Fn: ${fnName} AuthKey: ${p.fnAuthKey}`;
          if (p.logger) {
            p.logger({ fnName, details: msg, error: new Error(msg) });
          }

          return res.status(401).json({ status: 'unauthorized', details: msg });
        }

        try {
          await p.fns[fnName][p.fnAuthKey](req);
        } catch (e) {
          const msg = `Failed to pass auth fn for ${fnName}. AuthKey: ${p.fnAuthKey}`;
          if (p.logger) {
            p.logger({ fnName, details: msg, error: new Error(msg) });
          }
          return res.status(401).json({ status: 'unauthorized', details: msg });
        }

        p.emitAccountId(accountId);
        const acceptLanguages = req.get('accept-language') ?? '';
        const commaIndex = acceptLanguages.indexOf(',');
        const locale = commaIndex === -1 ? acceptLanguages : acceptLanguages.slice(0, commaIndex);

        const serverThisTranslate: ServerThisContext['translate'] = new Proxy(() => {}, {
          apply: (__, ___, args) => {
            const [arg1, arg2] = args;
            return i8nTranslate({ ...arg1, serverLocale: locale }, arg2);
          },
          get: (__, key) => {
            if (key === 'common') {
              const boundCommon = i8nTranslate.common(locale);
              return new Proxy(() => ({}), {
                apply: () => boundCommon,
                get: (___, commonKey) => boundCommon[commonKey]
              });
            } else {
              return i8nTranslate[key];
            }
          }
        }) as any;

        const r1 = await p.fns[fnName].call(
          {
            request: req,
            locale,
            translate: serverThisTranslate,
            dateFormatters: _.mapValues(dateFormatters, (fn) => (d: DateInput) => fn(d, locale))
          },
          req.body
        ); //Bind `this` to be an object with the raw request property and a translation function

        if (!isSerializable(r1)) {
          return res
            .status(500)
            .json({ status: 'Error: Return data cannot be passed over the wire. Must be a plain javascript object.' });
        }

        res.json(r1);
        // Put this after the return so it can finish sending pipe events
        await p.emitFnFinish({
          fnName,
          status: 'success',
          fnExecutionTimeMS: Date.now() - START,
          request: req
        });
        p.emitAccountId('NONE');
      } catch (er) {
        const { message, response, statusCode, error } = er instanceof Object ? er : ({} as any);

        await p.emitFnFinish({
          fnName,
          status: 'error',
          fnExecutionTimeMS: Date.now() - START,
          request: req,
          details: { errorMsg: message || 'NONE', body: req.body || {}, response: response?.data }
        });
        p.emitAccountId('NONE');

        try {
          console.error(er instanceof Error ? er : JSON.stringify(er, null, 2).slice(0, 250));
        } catch {
          console.error(er);
        }
        if (statusCode && typeof statusCode === 'number' && error && error instanceof Error) {
          return res.status(statusCode).json({ status: error.message || 'Error' });
        } else {
          return res.status(500).json({ error: message || 'Error' });
        }
      }
    });
  }
}

function attemptToDetermineUid(r: express.Request): string {
  try {
    return decode<any>(r.headers.authorization)?.user_id || 'UNKNOWN';
  } catch (e) {
    return 'UNKNOWN';
  }
}
