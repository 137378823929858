import { UseQueryResult, UseQueryOptions } from 'react-query';
export type UnpackPromise<T> = T extends Promise<infer U> ? U : T;
export type ArgumentType<F extends Function> = F extends (arg: infer A) => any ? A : never;
export type ReturnType<T> = T extends (...args: any[]) => infer R ? R : any;

type onDataFnType<T> = (a: T, meta: { shouldAddValueToCache: boolean }) => void;
type nextDataOptsType = {
  ensureSequentialTimestamp?: number;
  skipEqualityCheck?: boolean;
  shouldAddValueToCache?: boolean;
};

export class BifrostSubscription<T> {
  public dispose: () => void;
  public onData: (fn: onDataFnType<T>) => void;
  public onError: (fn: (a: Error) => void) => void;
  public nextData: (a: T, opts?: nextDataOptsType) => void;
  public nextError: (e: Error) => void;

  constructor(p: SubProps<T>) {
    this.dispose = p.dispose;
    this.onData = p.onData;
    this.onError = p.onError;
    this.nextData = p.nextData;
    this.nextError = p.nextError;
  }
}

export type UnpackBifrostSubscription<T> = T extends BifrostSubscription<infer U> ? U : T;

export type BifrostInstance<FunctionsType extends Record<string, Function>> = {
  [K in keyof FunctionsType]: BifrostInstanceFn<
    ArgumentType<FunctionsType[K]>,
    UnpackPromise<ReturnType<FunctionsType[K]>>
  >;
};

export type SubProps<T> = {
  dispose: () => void;
  onData: (fn: onDataFnType<T>) => void;
  onError: (fn: (a: Error) => void) => void;
  nextData: (a: T, opts?: nextDataOptsType) => void;
  nextError: (e: Error) => void;
};

export type FetchReturnType<T> = {
  data: T;
  isFromCache: boolean;
};

export type LimitedUseSubscriptionReturnType<T> = { data: T | undefined; error: any };
export type ExpandedUseSubscriptionReturnType<T> = {
  data: T | undefined;
  error: any;
  isFetchedAfterMount: boolean;
  isFromCache: boolean;
  isFetching: boolean;
  isLoading: boolean;
};
export type UseSubscriptionReturnType<T> = LimitedUseSubscriptionReturnType<T> | ExpandedUseSubscriptionReturnType<T>;

type BaseUseFetchReturnType = { forceRefresh: () => Promise<void>; error: any };
type LimitedUseFetchReturnType<T> = Pick<UseQueryResult<T>, 'data' | 'status' | 'error'> & BaseUseFetchReturnType;
export type ExpandedUseFetchReturnType<T> = UseQueryResult<T> & { isFromCache: boolean } & BaseUseFetchReturnType;
export type UseFetchReturnType<T> = LimitedUseFetchReturnType<T> | ExpandedUseFetchReturnType<T>;

export interface BifrostInstanceFn<ParamType, ResponseType> {
  getClientSubscription: (
    p: ParamType,
    options?: { disableCache?: boolean; onError?: (e: unknown) => void }
  ) => {
    subscribe: (fn: (p: FetchReturnType<UnpackBifrostSubscription<ResponseType>>) => void) => {
      unsubscribe: () => void;
    };
  };
  useClient(p: ParamType, options: ExpandedHelperOptions): ExpandedUseFetchReturnType<ResponseType>;
  useClient(p: ParamType, options?: LimitedHelperOptions): LimitedUseFetchReturnType<ResponseType>;
  fetchClient: (p: ParamType, options?: HelperOptions) => Promise<FetchReturnType<ResponseType>>;
  useClientSubscription(
    p: ParamType,
    options: ExpandedHelperOptions
  ): ExpandedUseSubscriptionReturnType<UnpackBifrostSubscription<ResponseType>>;
  useClientSubscription(
    p: ParamType,
    options?: LimitedHelperOptions
  ): LimitedUseSubscriptionReturnType<UnpackBifrostSubscription<ResponseType>>;
  useServer(p: ParamType, options: ExpandedHelperOptions): ExpandedUseFetchReturnType<ResponseType>;
  useServer(p: ParamType, options?: LimitedHelperOptions): LimitedUseFetchReturnType<ResponseType>;
  fetchServer: (
    p: ParamType,
    options?: HelperOptions
  ) => Promise<FetchReturnType<ResponseType>> & { cancel?: () => void };
  getCachedValue: (p: ParamType) => UnpackBifrostSubscription<ResponseType> | undefined;
  setCachedValue: (p: ParamType, newVal: UnpackBifrostSubscription<ResponseType>) => void;
  getAllCachedValues: () => (UseQueryResult<ResponseType> | undefined)[];
}

export type HttpProcessor = (p: { fnName: string; payload: any }) => Promise<any> & { cancel?: () => void };
export type Logger = (p: { fnName: string; details: any; error?: Error }) => any;

type HelperOptionsExtra = {
  extraDependencies?: (string | number | boolean | null | undefined)[]; //Lets you hint that there are extra things to depend on.
  useCacheOnlyWithinMS?: number;
  disableRefetchOnFocus?: boolean;
  disableCache?: boolean; // If set will ignore options
  skip?: boolean;
  forceRefetchAtTimestamp?: number;
};

type LimitedHelperOptions = UseQueryOptions & HelperOptionsExtra & { notifyOnMetaDataChanges?: false };
type ExpandedHelperOptions = UseQueryOptions & HelperOptionsExtra & { notifyOnMetaDataChanges: true };

export type HelperOptions = LimitedHelperOptions | ExpandedHelperOptions;

type BaseSubscriptionHelperOptions = {
  extraDependencies?: (string | number | boolean | null | undefined)[];
  disableCache?: boolean;
  skip?: boolean;
};

export type ExpandedSubscriptionHelperOptions = UseQueryOptions &
  BaseSubscriptionHelperOptions & { notifyOnMetaDataChanges: true };
export type LimitedSubscriptionHelperOptions = UseQueryOptions &
  BaseSubscriptionHelperOptions & { notifyOnMetaDataChanges?: false };
export type SubscriptionHelperOptions = ExpandedSubscriptionHelperOptions | LimitedSubscriptionHelperOptions;
